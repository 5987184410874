import { memo } from 'react';
import { Redirect } from '../i18n/redirect';

const Index = memo(() => (
  <Redirect />
));

Index.getLayout = (page) => page;

export default Index;
